import { useHistory, Link } from 'react-router-dom';
import { getImageByKey } from '../../scripts/getImageByKey';
import { useTranslation } from 'react-i18next';
import * as classes from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NavBarCategoryDropDown from '../NavBars/NavBarCategoryDropdown';
import * as settings from '../../config_settings.json';

const BottomBar2 = (props) => {
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <div className={`${classes.Footer} font-200`}>
      <div className={classes.Footer_Description}>
        <div className={classes.Footer_Description_Heading}>
          <img
            className='navBarSiteLogo'
            src={getImageByKey('logo_dtMedia')}
            title={t('Back to Home')}
            alt='SiteName'
            onClick={() => history.push('/')}
            style={props?.styles?.navBar}
          />
        </div>
        <div className={classes.Footer_Description_Heading_Info}>
          {/*Digital Tree is a OTT solution that provides сontent delivery platform
          and applications with cutting-edge features and business tools. If you
          want to create your own OTT Platform, visit
          https://staging1.digitaltree.icareus.com */}
          <br />
          Copyright © {new Date().getFullYear()} - PAIECASH. All rights reserved
        </div>
      </div>
      <div className={classes.Footer_Menu}>
        <div className={classes.Footer_Menu_Heading}>Menu</div>

        <div className={classes.Footer_Menu_info}>
          <div className={classes.Footer_Menu_Links}>
            <NavBarCategoryDropDown
              route={settings.routes.svodCategoriesRoute}
              footerStyles={true}
            />
          </div>
          <div className={classes.Footer_Menu_Links}>
            <Link to={`/${props.routes.contactus}`}>About us</Link>
          </div>
          <div className={classes.Footer_Menu_Links}>
            <Link to={`/${props.routes.tos}`}>Terms & Conditions</Link>
          </div>
          <div className={classes.Footer_Menu_Links}>
            <Link to={`/${props.routes.privacyPolicy}`}>Privacy Policy</Link>
          </div>
          <div className={classes.Footer_Menu_Links}>
            <Link to={`${props.routes.contactus}`}>Contact Us</Link>
          </div>
        </div>
      </div>
      <div className={classes.Footer_SocialMedia}>
        <div className={classes.Footer_SocialMedia_Heading}>Social Media :</div>
        <div className={classes.Footer_SocialMedia_Heading_Info}>
          <div className={classes.Footer_SocialMedia_Links}>
            <FontAwesomeIcon
              icon={['fab', 'facebook']}
              className={classes.Footer_SocialMedia_Links_icons}
            />{' '}
            <a href='' target='_blank' rel='noopener noreferrer'>
              Facebook{' '}
            </a>
          </div>

          <div className={classes.Footer_SocialMedia_Links}>
            <FontAwesomeIcon
              icon={['fab', 'twitter']}
              className={classes.Footer_SocialMedia_Links_icons}
            />
            <a href='' target='_blank' rel='noopener noreferrer'>
              Twitter{' '}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomBar2;
