import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { createAssetIdToken } from "../../scripts/utils";
import * as classes from "./RadiantPlayer.module.css";
import { useTranslation } from "react-i18next";
import { getImageByKey } from "../../scripts/getImageByKey";

export default function LiveEventPlayer(props) {
  console.log(props);
  const rmpPlayer = useRef();

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    let radiantscript, liveScript, eventScript;

    if (props.channelServiceId) {
      // no need vod.js for live event/video
      // need to load both live.js and event script for events which are  live
      const createScript = () => {
        radiantscript = document.createElement("script");
        liveScript = document.createElement("script");
        eventScript = document.createElement("script");

        radiantscript.src =
          "https://cdn.radiantmediatechs.com/rmp/6.4.11/js/rmp.min.js";

        liveScript.src =
          "https://staging1.icareus.com/lib/js/players/v3/players/live.js";

        eventScript.src =
          "https://staging1.icareus.com/lib/js/players/v1/players/events.js";

        eventScript.async = true;
        liveScript.async = true;
        radiantscript.async = true;

        document.body.appendChild(radiantscript);
        document.body.appendChild(liveScript);
        props.eventPlayer && document.body.appendChild(eventScript);
      };
      createScript();
      const labels = {
        error: {
          customErrorMessage: "This content is currently unavailable.",
          noSupportMessage:
            "Stream is not available, Please try again shortly.",
          noSupportDownload: "You may download video by clicking on that link",
          noSupportInstallChrome: "",
          noSupportInstallChromeLink: "https://www.google.com/chrome/",
        },
      };

      window._icareus = {};

      window._icareus.companyId = props.companyId;

      window._icareus.groupId = props.groupId;

      window._icareus.organizationId = props.organizationId;
      // itemID and serviceId is same and it must be passed
      window._icareus.itemId = props.channelServiceId;
      window._icareus.serviceId = props.channelServiceId;

      window._icareus.host = "https://suite.icareus.com";
      window._icareus.playerId = "rmpLivePlayer";
      window._icareus.playerType = "radiant";
      window._icareus.playerSetup = "startOnDemandPlayer";
      window._icareus.playerAutoStart = true;
      window._icareus.userId = props.userId;
      window._icareus.licenseFileUrl =
        "//d15n7gkgywbuz.cloudfront.net/suite/radiantplayerlicenses.json";
      window._icareus.streamRootKeysUrl =
        "//d15n7gkgywbuz.cloudfront.net/suite/streamrootkeys.json";

      window._icareus.token = createAssetIdToken(
        props.organizationId,
        props.channelServiceId,
        false,
        props.token
      );

      const backButtonHandler = () => {
        props.backRoute ? history.push(props.backRoute) : history.goBack();
      };

      const backCustomModule = {
        hint: t("Close"), // Then name of the module - will show as hint within player UI
        svg: getImageByKey("symbolX"), // Then SVG icon for the module
        svgHover: getImageByKey("symbolX"), // TODO: Image from /images, not web url
        // An optional second icon that can be displayed when the module is hovered
        callback: function () {
          // Our callback function
          backButtonHandler();
        },
      };

      // const customModule = [backCustomModule];
      const customModule = [];

      let settings;
      function functionOne(_callback) {
        if (!props.eventPlayer) return;
        window._icareus.eventIdActual = props.eventId;
        // assetID should always be 0 wheen it is live event
        window._icareus.assetId = 0;
        window.EVENT_ANALYTICS.init();
        _callback();
      }

      window.startOnDemandPlayer = function () {
        settings = {
          licenseKey: "Kl8lc3k9b3Y4MDJ5ZWk/cm9tNWRhc2lzMzBkYjBBJV8q",
          src: window._icareus.sources,

          autoHeightMode: true,
          autoHeightModeRatio: 1.7777777778,
          skin: props.skin ? props.skin : "s1",

          skinBackgroundColor: props.skinBackgroundColor
            ? props.skinBackgroundColor
            : "rgba(33, 33, 33, 0.85)",
          skinButtonColor: "rgba(255, 255, 255, 1)",
          skinAccentColor: "rgba(130, 177, 255, 1)",

          speed: props.speed ? true : false,
          automaticFullscreenOnLandscape: true,

          contentMetadata: {
            title: props.title ? props.title : null,
            description: props.description ? props.description : null,
            poster: [props.poster ? props.poster : window._icareus.thumbnail],
          },
          // custom message to player
          labels: labels,
          detectViewerLanguage: false,
          // end of custom message

          customModule: customModule,

          asyncElementID: rmpPlayer.current.id,
        };

        window._icareus.playerObject.init(settings);

        // we need to send event analytics after we have sent live analytics so this functionOne should be invoked at the end

        props.eventPlayer && functionOne(() => {});
      };

      return () => {
        document.body.removeChild(radiantscript);
        document.body.removeChild(liveScript);

        props.eventPlayer && document.body.removeChild(eventScript);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.channelServiceId]);

  return (
    <>
      <div className={classes.RadiantPlayer}>
        <div ref={rmpPlayer} id="rmpLivePlayer"></div>
      </div>
    </>
  );
}
