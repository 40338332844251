import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { createAssetIdToken } from "../../scripts/utils";
import { getImageByKey } from "../../scripts/getImageByKey";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useMyContext } from "../../contexts/StateHolder";
import { getAsset } from "../../scripts/dataHandlers";

//const { REACT_APP_RADIANT_LICENSE } = process.env;

export default function RadiantPlayer(props) {
  console.log(props);
  const rmpPlayer = useRef();

  const { chosenItem, language, key, setChosenItem, assetProperty, user } =
    useMyContext();

  const history = useHistory();

  // Setup translate function
  const { t } = useTranslation();

  useEffect(() => {
    let radiantscript, vodjs, advertjs;

    if (chosenItem && props.isSerie && !chosenItem.serie && props.itemId) {
      // finding ffolder name and id of series asset basically for google analytics
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const fetchChosenItem = async () => {
        const token5 = createAssetIdToken(
          props.organizationId,
          props.itemId,
          language,
          key
        );

        const res = await getAsset(
          props.organizationId,
          props.itemId,
          token5,
          language,
          assetProperty,
          user,
          source
        );
        console.log(res, "res assets");
        setChosenItem(res);
      };
      fetchChosenItem();
    }

    if (props.secretKey && props.itemId && chosenItem) {
      console.log(props.secretKey && props.itemId && chosenItem);
      const createScript = () => {
        radiantscript = document.createElement("script");
        vodjs = document.createElement("script");
        advertjs = document.createElement("script");

        radiantscript.src =
          "https://cdn.radiantmediatechs.com/rmp/6.4.11/js/rmp.min.js";
        radiantscript.async = true;

        // vodjs.src = 'https://my.icareus.com/lib/js/v4/players/vod.js';

        vodjs.async = true;
        vodjs.src =
          "https://staging1.icareus.com/lib/js/players/v6/players/vod.js";

        advertjs.async = true;
        advertjs.src =
          "https://staging1.icareus.com/lib/js/players/v6/players/adverts.js";

        document.body.appendChild(radiantscript);
        document.body.appendChild(vodjs);
        document.body.appendChild(advertjs);
      };
      createScript();

      window._icareus = {};
      const labels = {
        error: {
          customErrorMessage: "This content is currently unavailable.",
          noSupportMessage:
            "Stream is not available, Please try again shortly.",
          noSupportDownload: "You may download video by clicking on that link",
          noSupportInstallChrome: "",
          noSupportInstallChromeLink: "https://www.google.com/chrome/",
        },
      };
      window._icareus.companyId = props.companyId;

      window._icareus.groupId = props.groupId;

      window._icareus.organizationId = props.organizationId;

      window._icareus.itemId = props.itemId;

      window._icareus.host = "https://suite.icareus.com";
      window._icareus.playerId = "rmpPlayer";
      window._icareus.playerType = "radiant";
      window._icareus.playerSetup = "vodJSCallback";
      window._icareus.playerAutoStart = true;
      window._icareus.userId = props.userId;

      // vod adverts stuff start

      window._icareus.campaignId = props.campaignIDD;
      window._icareus.advertVOD = props.campaignIDD ? true : false;
      window._icareus.useAdvertsLibrary = props.campaignIDD ? true : false;

      window._icareus.applicationTypeId = 30;
      window._icareus.applicationId = 133485121;

      // vod adverts end here

      window._icareus.token = createAssetIdToken(
        props.organizationId,
        props.itemId,
        false,
        props.secretKey
      );

      // google analytics as instructed by digital starts here
      window._icareus.gaLabel = ` ${
        chosenItem?.name
          ? chosenItem.name.toUpperCase()
          : chosenItem.title.toUpperCase()
      }[${props.itemId}]`;
      window._icareus.gaCategory = props.isSerie ? "Series" : "Videos";
      window._icareus.gatrackingId = props.googleTrackingId;

      window._icareus.isSerie = props.isSerie ? props.isSerie : false;
      window._icareus.gaFolderLabel = props.isSerie
        ? `${chosenItem.serie?.title}[${chosenItem.serie?.id}]`
        : chosenItem.folders && chosenItem.folders.length > 0
        ? `${chosenItem.folders[0].name}/${chosenItem.folders.id}`
        : "Demo Title For Videos";
      window._icareus.gaFolderCategory = "Folder";

      // google analytics as instructed by digital ends here

      window._icareus.initializedByReactApps = false;

      const backButtonHandler = () => {
        if (props.backRoute === undefined) {
          history.goBack();
        } else if (props.backRoute === "hidden") {
          props.setShowPlayer("wantedHidden");
        } else {
          history.push(props.backRoute);
        }
        //props.backRoute ? history.push(props.backRoute) :
      };

      // Then we define a custom module - in this case a 10s rewind module
      const backCustomModule = {
        hint: t("Close"), // Then name of the module - will show as hint within player UI
        svg: getImageByKey("symbolX"), // Then SVG icon for the module
        svgHover: getImageByKey("symbolX"), // TODO: Image from /images, not web url
        // An optional second icon that can be displayed when the module is hovered
        callback: function () {
          // Our callback function
          backButtonHandler();
        },
      };

      // captions
      // Your WebVTT closed captions
      // const ccFiles = [
      //   [
      //     'en',
      //     'English',
      //     'https://www.radiantmediaplayer.com/media/vtt/captions/cc.vtt',
      //   ],
      //   [
      //     'fr',
      //     'Français',
      //     'https://www.radiantmediaplayer.com/media/vtt/captions/cc-fr.vtt',
      //   ],
      // ];
      let settings;

      // function functionOne(_callback) {
      //   window._icareus.eventIdActual = 1408801;
      //   window._icareus.assetId = 1407203;
      //   window.EVENT_ANALYTICS.init();

      //   _callback();
      // }

      window.vodJSCallback = function () {
        // Then we set our player settings
        settings = {
          // custom message to player
          labels: labels,
          detectViewerLanguage: false,
          // end of custom message

          // to set thubnail on hover
          // seekBarThumbnailsLoc:
          //   'https://www.radiantmediaplayer.com/media/vtt/thumbnails/bbb-thumbnails.vtt',

          licenseKey: window.location.hostname.includes("icareus.com")
            ? `Kl8lc3k9b3Y4MDJ5ZWk/cm9tNWRhc2lzMzBkYjBBJV8q`
            : "Kl8lc3k9b3Y4MDJ5ZWk/cm9tNWRhc2lzMzBkYjBBJV8q", // ${REACT_APP_RADIANT_LICENSE} TODO: IT WORKS IN KOTIKINO, WHY NOT HERE?
          src: window._icareus.sources,

          // height and width selected based on 16/9 aspect ration
          autoHeightMode: true,
          autoHeightModeRatio: 1.7777777778,
          // Let us select a skin (options aree s1,s2,s3 and s4)
          skin: props.skin ? props.skin : "s1",

          // skinBackgroundColor should be rgba
          skinBackgroundColor: props.skinBackgroundColor
            ? props.skinBackgroundColor
            : "rgba(33, 33, 33, 0.85)",
          skinButtonColor: "rgba(255, 255, 255, 1)",
          skinAccentColor: "rgba(130, 177, 255, 1)",

          // this is for playback speed
          speed: props.speed ? true : false,
          automaticFullscreenOnLandscape: true,
          ads: props.campaignIDD ? true : false,

          adTagUrl: window._icareus.videoVastURL,

          // Let us add a poster frame to our player

          contentMetadata: {
            title: props.title ? props.title : null,
            description: props.description ? props.description : null,
            poster: [props.poster ? props.poster : window._icareus.thumbnail],
          },

          ccFiles: null,
          ...(props.backButton
            ? { customModule: [{ ...backCustomModule }] }
            : {}),

          // Here we pass the ID of the player container so that the core library may automatically initialise player when it finishes loading
          asyncElementID: rmpPlayer.current.id,
        };

        window._icareus.playerObject.init({ ...settings });
      };
      console.log(window);
      return () => {
        document.body.removeChild(radiantscript);
        document.body.removeChild(vodjs);
        document.body.removeChild(advertjs);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={rmpPlayer} id="rmpPlayer"></div>;
}
